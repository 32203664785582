import React, { Fragment, useEffect, useState } from 'react';
import Select from 'react-select';
import { Label } from 'reactstrap';
import '../assets/css/index.css';
import MachineService from '../services/MachineService';
import { DataQualityType } from '../types';

function DataQuality() {
  const [dataQuality, setDataQuality] = useState<DataQualityType>();
  const [frequency, setFrequency] = useState<string>('weekly');
  const [period, setPeriod] = useState<string>('previous');

  useEffect(() => {
    window.scrollTo(0, 0);

    MachineService.getDataQuality(frequency, period).then((data) => {
      setDataQuality(data);
    });
  }, [frequency, period]);

  const FrequencyOptions = [
    { label: 'Weekly', value: 'weekly' },
    { label: 'Monthly', value: 'monthly' },
    { label: 'Yearly', value: 'yearly' },
  ];

  const PeriodOptions = [
    { label: 'Previous', value: 'previous' },
    { label: 'Last Year', value: 'last_year' },
  ];

  const isLoading = dataQuality === undefined;

  return isLoading ? (
    <Fragment>
      <div className="loader"></div>
    </Fragment>
  ) : (
    <Fragment>
      <div>
        <div className="flex justify-center">
          <h1 className="py-4">Data Quality Dashboard</h1>
        </div>
        <div className="flex justify-around">
          <div className="flex flex-col w-1/4">
            <Label htmlFor="frequency">Frequency</Label>
            <Select
              options={FrequencyOptions.map((frequency) => ({
                label: frequency.label,
                value: frequency.value,
              }))}
              name="frequency"
              placeholder={'weekly'}
              onChange={(submitted) =>
                setFrequency(submitted ? submitted.value : 'weekly')
              }
            />
          </div>
          <div className="flex flex-col w-1/4">
            <Label htmlFor="frequency">Period</Label>
            <Select
              options={PeriodOptions.map((period) => ({
                label: period.label,
                value: period.value,
              }))}
              name="period"
              placeholder={'previous'}
              onChange={(submitted) =>
                setPeriod(submitted ? submitted.value : 'wpreviouseekly')
              }
            />
          </div>
        </div>
        <hr />
        <div className="flex justify-around pt-4">
          <div className="flex flex-col">
            <h3>Total Machines</h3>
            <div className="flex justify-between">
              <span>Current:</span>
              <span>{dataQuality['total-machines']?.current}</span>
            </div>
            <div className="flex justify-between">
              <span>Past:</span>
              <span>{dataQuality['total-machines']?.past}</span>
            </div>
            <div className="flex justify-between">
              <span>Difference:</span>
              <span>{dataQuality['total-machines']?.difference}</span>
            </div>
          </div>
          <div className="flex flex-col">
            <h3>Good Machines</h3>
            <div className="flex justify-between">
              <span>Current:</span>
              <span>{dataQuality['good-machines']?.current.toFixed(2)} %</span>
            </div>
            <div className="flex justify-between">
              <span>Past:</span>
              <span>{dataQuality['good-machines']?.past.toFixed(2)} %</span>
            </div>
            <div className="flex justify-between">
              <span>Difference:</span>
              <span>
                {dataQuality['good-machines']?.difference.toFixed(2)} %
              </span>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
export default DataQuality;
