const checkIsEmpty = (e: React.FormEvent, ...fieldsToCheck: string[]) => {
  const wrongFieldsArray: string[] = [];
  fieldsToCheck.forEach((field) => {
    const fieldInput = (e.target as HTMLFormElement).elements.namedItem(
      field
    ) as HTMLInputElement;

    if (fieldInput.value.length === 0) {
      wrongFieldsArray.push(field);
    }
  });
  return wrongFieldsArray;
};

const checkIsOverLength = (
  e: React.FormEvent,
  maxLength: number,
  ...fieldsToCheck: string[]
) => {
  const wrongFieldsArray: string[] = [];
  fieldsToCheck.forEach((field) => {
    const fieldInput = (e.target as HTMLFormElement).elements.namedItem(
      field
    ) as HTMLInputElement;

    if (fieldInput.value.length > maxLength) {
      wrongFieldsArray.push(field);
    }
  });
  return wrongFieldsArray;
};

export { checkIsEmpty, checkIsOverLength };
