import * as Sentry from '@sentry/react';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './app/store';
import './assets/css/index.css';
import App from './components/App';

if (
  process.env.NODE_ENV !== undefined ||
  process.env.NODE_ENV !== 'development'
) {
  Sentry.init({
    dsn: 'https://d0dfeca4331140fbb1d9ff5b124d8138@o1214979.ingest.sentry.io/4504037879185408',
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 0.2,
    environment: process.env.NODE_ENV,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
