import React, { Fragment, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import AddMachine from '../pages/AddMachine';
import BrandsAndModels from '../pages/BrandsAndModels';
import DataQuality from '../pages/DataQuality';
import EditMachine from '../pages/EditMachine';
import LabelingApp from '../pages/LabelingApp';
import MachineDetail from '../pages/MachineDetail';
import MachinesTable from '../pages/MachinesTable';
import AuthService from '../services/AuthService';
import Footer from './Footer';
import SiteNavbar from './SiteNavbar';

function Machines() {
  const navigate = useNavigate();
  const user = AuthService.getCurrentUser();
  const pathName = window.location.pathname;
  const pathSearch = window.location.search;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!user) {
      const searchParams = new URLSearchParams();
      searchParams.append('from', pathName);
      searchParams.append('search', pathSearch);
      navigate('/login?' + searchParams.toString());
    }
  });

  return (
    <Fragment>
      <SiteNavbar />
      <div className="container">
        <Routes>
          <Route path="machines/page/:num" element={<MachinesTable />} />
          <Route path="machine/add" element={<AddMachine />} />
          <Route path="machine/:id/view" element={<MachineDetail />} />
          <Route path="machine/:id/edit" element={<EditMachine />} />
          <Route path="/labeling-app" element={<LabelingApp />} />
          <Route path="/brands-models" element={<BrandsAndModels />} />
          <Route path="/data-quality" element={<DataQuality />} />
        </Routes>
      </div>
      <Footer />
    </Fragment>
  );
}

export default Machines;
