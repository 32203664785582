import axios from 'axios';
import { PartialBrand, PartialMachine, PartialModel } from '../types';

class MachineService {
  private token: string;
  private API: string;

  constructor() {
    this.token = localStorage.getItem('token') ?? '';
    this.API = process.env.REACT_APP_API_URL + 'industrial-machine/';
  }

  setToken(token: string) {
    this.token = token;
  }

  apiGet(url: string) {
    return axios({
      url: url,
      method: 'GET',
      headers: {
        Authorization: `Token ${this.token}`,
      },
    }).then((response) => {
      return response.data;
    });
  }

  getFilteredMachines(page: number, limit: number) {
    let url = '';
    const search = '';

    const searchParams = new URLSearchParams(search);
    const searchURL = new URLSearchParams(document.location.search);

    if (searchURL.has('search')) {
      const searchValue = searchURL.get('search');
      if (searchValue !== null) {
        searchParams.set('search', searchValue);
      }
    }

    if (searchURL.has('source')) {
      const sourceValue = searchURL.get('source');
      if (sourceValue !== null) {
        searchParams.set('source', sourceValue);
      }
    }
    if (searchURL.has('source_format')) {
      const sourceFormatValue = searchURL.get('source_format');
      if (sourceFormatValue !== null) {
        searchParams.set('source_format', sourceFormatValue);
      }
    }
    if (searchURL.has('only_with_price')) {
      const onlyWithPriceValue = searchURL.get('only_with_price');
      if (onlyWithPriceValue !== null) {
        searchParams.set('only_with_price', onlyWithPriceValue);
      } else {
        searchParams.set('only_with_price', 'false');
      }
    }
    if (searchURL.has('only_available')) {
      const onlyAvailableValue = searchURL.get('only_available');
      if (onlyAvailableValue !== null) {
        searchParams.set('only_available', onlyAvailableValue);
      } else {
        searchParams.set('only_available', 'false');
      }
    }
    if (searchURL.has('only_with_year')) {
      const onlyWithYearValue = searchURL.get('only_with_year');
      if (onlyWithYearValue !== null) {
        searchParams.set('only_with_year', onlyWithYearValue);
      } else {
        searchParams.set('only_with_year', 'false');
      }
    }
    if (searchURL.has('search_description')) {
      const searchDescriptionValue = searchURL.get('search_description');
      if (searchDescriptionValue !== null) {
        searchParams.set('search_description', searchDescriptionValue);
      } else {
        searchParams.set('search_description', 'false');
      }
    }
    if (searchURL.has('condition')) {
      const conditionValue = searchURL.get('condition');
      if (conditionValue !== null) {
        searchParams.set('condition', conditionValue);
      }
    }
    if (searchURL.has('strict_search')) {
      const strictSearchValue = searchURL.get('strict_search');
      if (strictSearchValue !== null) {
        searchParams.set('strict_search', strictSearchValue);
      }
    }
    if (searchURL.has('start_date')) {
      const startDateValue = searchURL.get('start_date');
      if (startDateValue !== null) {
        searchParams.set('from', startDateValue);
      }
    }
    if (searchURL.has('end_date')) {
      const endDateValue = searchURL.get('end_date');
      if (endDateValue !== null) {
        searchParams.set('to', endDateValue);
      }
    }

    searchParams.set('limit', String(limit));
    searchParams.set('offset', String(limit * (page - 1)));

    searchParams.toString();
    url = this.API + '?' + searchParams.toString();

    return this.apiGet(url);
  }

  getSingleMachine(id: string) {
    const url = this.API + id;

    return this.apiGet(url);
  }

  getAllSources() {
    const url = this.API + 'source/';

    return this.apiGet(url);
  }

  getAllSourceFormats() {
    const url = this.API + 'source-format/';

    return this.apiGet(url);
  }

  createMachine(machine: PartialMachine) {
    return axios({
      url: this.API,
      method: 'POST',
      headers: {
        Authorization: `Token ${this.token}`,
      },
      data: machine,
    }).then((response) => {
      return response.data;
    });
  }

  updateMachine(id: string, machine: PartialMachine) {
    return axios({
      url: this.API + id + '/',
      method: 'PUT',
      headers: {
        Authorization: `Token ${this.token}`,
      },
      data: machine,
    });
  }

  deleteMachine(id: string) {
    return axios({
      url: this.API + id + '/',
      method: 'DELETE',
      headers: {
        Authorization: `Token ${this.token}`,
      },
    });
  }

  updateAvailability(id: string, new_available: boolean) {
    return axios({
      url: this.API + 'availability/' + id + '/',
      method: 'PUT',
      headers: {
        Authorization: `Token ${this.token}`,
      },
      data: { available: new_available },
    });
  }

  getLabelingMachines() {
    const url = this.API + 'labeling/';

    return this.apiGet(url);
  }

  getAllModels() {
    const url = this.API + 'model/?limit=99999';

    return this.apiGet(url);
  }

  getAllBrands() {
    const url = this.API + 'brand/?limit=99999';

    return this.apiGet(url);
  }

  getAllCategories() {
    const url = this.API + 'category/';

    return this.apiGet(url);
  }

  getAllApplicationTypes() {
    const url = this.API + 'application-type/';

    return this.apiGet(url);
  }

  getAllMachineTypes() {
    const url = this.API + 'type/';

    return this.apiGet(url);
  }

  addBrand(brand: PartialBrand) {
    return axios({
      url: this.API + 'brand/',
      method: 'POST',
      headers: {
        Authorization: `Token ${this.token}`,
      },
      data: brand,
    });
  }

  addModel(model: PartialModel) {
    return axios({
      url: this.API + 'model/',
      method: 'POST',
      headers: {
        Authorization: `Token ${this.token}`,
      },
      data: model,
    });
  }

  getDataQuality(frequency: string, period: string) {
    const url = `${this.API}data-quality/?freq=${frequency}&period=${period}`;

    return this.apiGet(url);
  }
}

export default new MachineService();
