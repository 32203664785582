import React, { Fragment } from 'react';
import '../assets/css/footer.css';

function Footer() {
  return (
    <Fragment>
      <div className="footer">
        Copyright © 2020.{' '}
        <a href="https://www.gindumac.com/" target="_blank" rel="noreferrer">
          GINDUMAC
        </a>
        , All rights reserved.
      </div>
    </Fragment>
  );
}

export default Footer;
