import React, { Fragment, useEffect, useState } from 'react';
import { BsToggleOff, BsToggleOn } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import Select, { MultiValue } from 'react-select';
import {
  Button,
  Col,
  Collapse,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import MachineService from '../services/MachineService';
import './../assets/css/searcher.css';
import { APIResponse, SelectOptions, Source, SourceFormat } from './../types';

export interface SearcherParams {
  setReload: React.Dispatch<React.SetStateAction<boolean>>;
  page?: number;
}

function Searcher({ setReload, page }: SearcherParams) {
  const navigate = useNavigate();

  const URLsearch = window.location.search;
  const searchParams = new URLSearchParams(URLsearch);
  const [searchChanged, setSearchChanged] = useState(false);

  const toBool = (val: string | null) => {
    return val && val === 'true' ? true : false;
  };

  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState(searchParams.get('search'));
  const [platformValue, setPlatformValue] = useState(
    searchParams.get('source')
  );
  const [platformFormatValue, setPlatformFormatValue] = useState(
    searchParams.get('source_format')
  );
  const [conditionValue, setConditionValue] = useState(
    searchParams.has('condition') ? searchParams.get('condition') : 'all'
  );
  const [countryValue, setCountryValue] = useState('');
  const [onlyAvailableValue, setOnlyAvailableValue] = useState(
    toBool(searchParams.get('only_available'))
  );
  const [onlyWithPriceValue, setOnlyWithPriceValue] = useState(
    searchParams.has('only_with_price')
      ? toBool(searchParams.get('only_with_price'))
      : true
  );
  const [onlyWithYearValue, setOnlyWithYearValue] = useState(
    searchParams.has('only_with_year')
      ? toBool(searchParams.get('only_with_year'))
      : true
  );
  const [strictSearchValue, setStrictSearchValue] = useState(
    toBool(searchParams.get('strict_search'))
  );
  const [searchDescriptionValue, setSearchDescriptionValue] = useState(
    toBool(searchParams.get('search_description'))
  );
  const [startDateValue, setStartDateValue] = useState<
    string | undefined | null
  >(searchParams.get('start_date'));
  const [endDateValue, setEndDateValue] = useState<string | undefined | null>(
    searchParams.get('end_date')
  );

  const [sources, setSources] = useState<APIResponse<Source>>();
  const [sourceFormats, setSourceFormats] =
    useState<APIResponse<SourceFormat>>();

  const urlSearchConstructor = () => {
    const searchParamsConstructor = new URLSearchParams();

    if (searchValue) searchParamsConstructor.append('search', searchValue);
    if (countryValue.length > 0)
      searchParamsConstructor.append('country', countryValue);
    if (platformValue) searchParamsConstructor.append('source', platformValue);
    if (platformFormatValue)
      searchParamsConstructor.append('source_format', platformFormatValue);
    if (conditionValue)
      searchParamsConstructor.append('condition', conditionValue);
    if (startDateValue)
      searchParamsConstructor.append('start_date', startDateValue);
    if (endDateValue) searchParamsConstructor.append('end_date', endDateValue);
    searchParamsConstructor.append(
      'only_with_price',
      String(onlyWithPriceValue)
    );
    searchParamsConstructor.append('only_with_year', String(onlyWithYearValue));
    if (strictSearchValue)
      searchParamsConstructor.append(
        'strict_search',
        String(strictSearchValue)
      );
    if (searchDescriptionValue)
      searchParamsConstructor.append(
        'search_description',
        String(searchDescriptionValue)
      );
    if (onlyAvailableValue)
      searchParamsConstructor.append(
        'only_available',
        String(onlyAvailableValue)
      );

    navigate(
      `/machines/page/${
        searchChanged ? 1 : page ? page : 1
      }?${searchParamsConstructor.toString()}`
    );
  };

  useEffect(() => {
    urlSearchConstructor();

    setReload(true);

    let mounted = true;
    MachineService.getAllSources().then((data) => {
      if (mounted) {
        setSources(data);
      }
    });
    MachineService.getAllSourceFormats().then((data) => {
      if (mounted) {
        setSourceFormats(data);
      }
    });
    return () => {
      mounted = false;
    };
  }, [
    countryValue,
    platformValue,
    platformFormatValue,
    conditionValue,
    searchValue,
    onlyWithPriceValue,
    onlyAvailableValue,
    onlyWithYearValue,
    strictSearchValue,
    searchDescriptionValue,
    startDateValue,
    endDateValue,
  ]);

  const handleOnChangeSearch = (e: React.FormEvent) => {
    setSearchValue((e.target as HTMLInputElement).value);
  };

  const handleOnChangeCountry = (val: string) => {
    setCountryValue(val);
  };

  const handleOnChangePlatform = (val: MultiValue<SelectOptions>) => {
    let platforms = '';
    val.map((platform) => (platforms += platform.value + ','));
    setPlatformValue(platforms);
    setSearchChanged(true);
  };

  const handleOnChangePlatformFormat = (val: MultiValue<SelectOptions>) => {
    let platform_formats = '';
    val.map(
      (platform_format) => (platform_formats += platform_format.value + ',')
    );
    setPlatformFormatValue(platform_formats);
    setSearchChanged(true);
  };

  const handleOnChangeCondition = (val: string | undefined) => {
    val ? setConditionValue(val) : '';
    setSearchChanged(true);
  };

  const handleOnChangeOnlyWithPrice = (e: React.FormEvent) => {
    setOnlyWithPriceValue((e.target as HTMLInputElement).checked);
  };

  const handleOnChangeOnlyAvailable = (e: React.FormEvent) => {
    setOnlyAvailableValue((e.target as HTMLInputElement).checked);
  };

  const handleOnChangeOnlyWithYear = (e: React.FormEvent) => {
    setOnlyWithYearValue((e.target as HTMLInputElement).checked);
  };

  const handleOnChangeStrictSearch = (e: React.FormEvent) => {
    setStrictSearchValue((e.target as HTMLInputElement).checked);
  };

  const handleSearchDescription = (e: React.FormEvent) => {
    setSearchDescriptionValue((e.target as HTMLInputElement).checked);
  };

  const handleOnChangeStartDate = (e: React.FormEvent) => {
    setStartDateValue((e.target as HTMLInputElement).value);
  };

  const handleOnChangeEndDate = (e: React.FormEvent) => {
    setEndDateValue((e.target as HTMLInputElement).value);
  };

  const resetStartDate = () => {
    setStartDateValue(undefined);
    const startDateInput =
      document.querySelector<HTMLInputElement>('#start_date');
    if (startDateInput) {
      startDateInput.value = '';
    }
  };

  const resetEndDate = () => {
    setEndDateValue(undefined);
    const endDateInput = document.querySelector<HTMLInputElement>('#end_date');
    if (endDateInput) {
      endDateInput.value = '';
    }
  };

  const CountryOptions = [
    { label: 'country 1', value: 'country_1' },
    { label: 'country 2', value: 'country_2' },
    { label: 'country 3', value: 'country_3' },
    { label: 'country 4', value: 'country_4' },
  ];

  const ConditionOptions = [
    { label: 'all', value: 'all' },
    { label: 'new', value: 'new' },
    { label: 'used', value: 'used' },
  ];

  const PlatformOptions: SelectOptions[] = [];
  const PlatformFormatOptions: SelectOptions[] = [];

  const toggle = () => setIsOpen(!isOpen);

  if (sources === undefined || sourceFormats === undefined) {
    return (
      <Fragment>
        <div className="loader"></div>
      </Fragment>
    );
  } else {
    sources.results.map((source) =>
      PlatformOptions.push({ label: source.name, value: source.name })
    );

    sourceFormats.results.map((sourceFormat) =>
      PlatformFormatOptions.push({
        label: sourceFormat.name,
        value: sourceFormat.name,
      })
    );

    return (
      <Fragment>
        <div
          className="searcher-container"
          onChange={() => setSearchChanged(true)}
        >
          <div className="flex h-8 m-0 mt-4 border-r-3 justify-between">
            <Input
              onChange={handleOnChangeSearch}
              defaultValue={searchValue ? searchValue : ''}
              placeholder="Search..."
              className="w-3/4"
            />
            <Link to={'/machine/add'}>
              <Button color="warning" className="w-36">
                Add Machine
              </Button>
            </Link>
          </div>
          <div className="search_options-toggle" onClick={toggle}>
            {isOpen ? <BsToggleOn /> : <BsToggleOff />}

            <span>Search options</span>
          </div>
          <Collapse isOpen={isOpen}>
            <Form>
              <Row>
                <Col md={6} hidden>
                  <FormGroup>
                    <Label for="country">Country</Label>
                    <Select
                      onChange={() => handleOnChangeCountry}
                      options={CountryOptions}
                      name="country"
                      className="w-full"
                      isDisabled
                      isMulti
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label for="start_date">From</Label>
                    <Input
                      type="date"
                      name="start_date"
                      id="start_date"
                      onChange={handleOnChangeStartDate}
                      defaultValue={startDateValue ? startDateValue : ''}
                    />
                    <span className="reset-btn" onClick={resetStartDate}>
                      Reset
                    </span>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label for="end_date">To</Label>
                    <Input
                      type="date"
                      name="end_date"
                      id="end_date"
                      onChange={handleOnChangeEndDate}
                      defaultValue={endDateValue ? endDateValue : ''}
                    />
                    <span className="reset-btn" onClick={resetEndDate}>
                      Reset
                    </span>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="platform">Platform</Label>
                    <Select
                      onChange={(val) => handleOnChangePlatform(val)}
                      options={PlatformOptions}
                      className="w-full"
                      defaultValue={PlatformOptions.filter((platform) =>
                        platformValue?.split(',').includes(platform.value)
                      )}
                      isMulti
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="platform_format">Platform Format</Label>
                    <Select
                      onChange={(val) => handleOnChangePlatformFormat(val)}
                      options={PlatformFormatOptions}
                      name="platform_format"
                      className="w-full"
                      defaultValue={PlatformFormatOptions.filter(
                        (platformFormat) =>
                          platformFormatValue
                            ?.split(',')
                            .includes(platformFormat.value)
                      )}
                      isMulti
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="condition">Condition</Label>
                    <Select
                      onChange={(val) => handleOnChangeCondition(val?.value)}
                      options={ConditionOptions}
                      name="condition"
                      className="w-full"
                      defaultValue={ConditionOptions.find(
                        (condition) => condition.value === conditionValue
                      )}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Row>
                  <Col md={2}>
                    Price
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          name="only_with_price"
                          onChange={handleOnChangeOnlyWithPrice}
                          defaultChecked={onlyWithPriceValue}
                        />
                        {' Only with price'}
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    Manufacturing year
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          name="strict_search"
                          onChange={handleOnChangeOnlyWithYear}
                          defaultChecked={onlyWithYearValue}
                        />{' '}
                        Only with year
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
              </FormGroup>
              Other options
              <Row>
                <Col md={2}>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        name="strict_search"
                        onChange={handleOnChangeOnlyAvailable}
                        defaultChecked={onlyAvailableValue}
                      />{' '}
                      Only available
                    </Label>
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        name="strict_search"
                        onChange={handleOnChangeStrictSearch}
                        defaultChecked={strictSearchValue}
                      />{' '}
                      Strict search
                    </Label>
                  </FormGroup>
                </Col>
                <Col md={2} lg={3}>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        name="search_description"
                        onChange={handleSearchDescription}
                        defaultChecked={searchDescriptionValue}
                      />{' '}
                      Search description
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
            <hr />
          </Collapse>
        </div>
      </Fragment>
    );
  }
}

export default Searcher;
