import React, { Fragment } from 'react';
import { Link, NavigateFunction, useNavigate } from 'react-router-dom';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import '../assets/css/table.css';

const paginationItem = (
  i: number,
  changePage: (_event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void,
  page: number
) => {
  return (
    <PaginationItem key={i}>
      <PaginationLink
        onClick={(event) => {
          changePage(event);
        }}
        value={i}
        className={i === page ? 'current-page' : ''}
      >
        {i}
      </PaginationLink>
    </PaginationItem>
  );
};

function renderPaginator(
  machinesCount: number,
  showEntriesNum: number,
  page: number,
  changePage: (_event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
) {
  const render = [];

  if (page > 4 && page < Math.ceil(machinesCount / showEntriesNum) - 2) {
    for (let i = page - 3; i <= page + 3; i++) {
      render.push(paginationItem(i, changePage, page));
    }
  } else if (page >= Math.ceil(machinesCount / showEntriesNum) - 2) {
    for (
      let i = Math.ceil(machinesCount / showEntriesNum) - 6;
      i <= Math.ceil(machinesCount / showEntriesNum);
      i++
    ) {
      if (i >= 1) {
        render.push(paginationItem(i, changePage, page));
      }
    }
  } else {
    for (
      let i = 1;
      i <= Math.ceil(machinesCount / showEntriesNum) && i <= 7;
      i++
    ) {
      render.push(paginationItem(i, changePage, page));
    }
  }
  return render;
}

interface PaginatorParams {
  machinesCount: number;
  showEntriesNum: number;
  page: number;
  history: NavigateFunction;
}

function Paginator({ machinesCount, showEntriesNum, page }: PaginatorParams) {
  const navigate = useNavigate();

  const changePage = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    navigate(
      '/machines/page/' +
        (event.target as HTMLInputElement).value +
        window.location.search
    );
  };

  return (
    <Fragment>
      <Pagination aria-label="Page navigation">
        {page !== 1 ? (
          <>
            <PaginationItem>
              <Link
                to={'/machines/page/1' + window.location.search}
                className="no-underline"
              >
                <PaginationLink first />
              </Link>
            </PaginationItem>
            <PaginationItem>
              <Link
                to={
                  page > 1
                    ? '/machines/page/' + (page - 1) + window.location.search
                    : '/machines/page/1'
                }
                className="no-underline"
              >
                <PaginationLink previous />
              </Link>
            </PaginationItem>
          </>
        ) : (
          <>
            <PaginationItem className="dissabled">
              <span>
                <PaginationLink first />
              </span>
            </PaginationItem>
            <PaginationItem className="dissabled">
              <span>
                <PaginationLink previous />
              </span>
            </PaginationItem>
          </>
        )}
        {renderPaginator(machinesCount, showEntriesNum, page, changePage)}
        {page <= machinesCount / showEntriesNum ? (
          <>
            <PaginationItem>
              <Link
                to={
                  page < machinesCount / showEntriesNum
                    ? '/machines/page/' + (page + 1 + window.location.search)
                    : '/machines/page/1'
                }
                className="no-underline"
              >
                <PaginationLink next />
              </Link>
            </PaginationItem>
            <PaginationItem>
              <Link
                to={
                  '/machines/page/' +
                  Math.ceil(machinesCount / showEntriesNum) +
                  window.location.search
                }
                className="no-underline"
              >
                <PaginationLink last />
              </Link>
            </PaginationItem>
          </>
        ) : (
          <>
            <PaginationItem className="dissabled">
              <span>
                <PaginationLink next />
              </span>
            </PaginationItem>
            <PaginationItem className="dissabled">
              <span>
                <PaginationLink last />
              </span>
            </PaginationItem>
          </>
        )}
      </Pagination>
    </Fragment>
  );
}

export default Paginator;
