import React, { Fragment, useEffect, useState } from 'react';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { Link, useLocation, useParams } from 'react-router-dom';
import { Alert, Button } from 'reactstrap';
import '../assets/css/index.css';
import '../assets/css/machine-info.css';
import SelectModal from '../components/SelectModal';
import MachineService from '../services/MachineService';
import { Machine } from '../types';

function MachineDetail() {
  const { id } = useParams();
  const location = useLocation();
  const [machine, setMachine] = useState<Machine>();

  useEffect(() => {
    window.scrollTo(0, 0);

    let mounted = true;
    id
      ? MachineService.getSingleMachine(id).then((data) => {
          if (mounted) {
            setMachine(data);
          }
        })
      : null;
    return () => {
      mounted = false;
    };
  }, [id]);

  if (machine === undefined) {
    return (
      <Fragment>
        <div className="loader"></div>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <div className="container machine_info-container">
          <Link
            to={
              location.state?.backURL
                ? location.state.backURL
                : '/machines/page/1'
            }
          >
            <IoMdArrowRoundBack className="arrow-back" />
          </Link>
          <h2 className="border-bottom-black">Basic Machine Information</h2>
          <div className="row">
            <div className="col-md-6">
              <label htmlFor="plain_brand">Plain Brand</label>
              <p>{machine.plain_brand ? machine.plain_brand : 'N/A'}</p>
            </div>
            <div className="col-md-6">
              <label htmlFor="plain_model">Plain Model</label>
              <p>{machine.plain_model ? machine.plain_model : 'N/A'}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label htmlFor="year_manufactured">Year Manufactured</label>
              <p>
                {machine.manufacturing_year
                  ? machine.manufacturing_year
                  : 'N/A'}
              </p>
            </div>
            <div className="col-md-6">
              <label htmlFor="condition">Condition</label>
              <p>{machine.condition ? machine.condition : 'N/A'}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label htmlFor="available">Available</label>
              <p>
                {typeof machine.availability?.available !== 'undefined'
                  ? machine.availability?.available
                    ? 'Yes'
                    : 'No'
                  : 'N/A'}
              </p>
            </div>
            <div className="col-md-6">
              <label htmlFor="useful">Useful</label>
              <p>
                {machine.meta_data === null
                  ? 'N/A'
                  : typeof machine.meta_data.wanted !== 'undefined'
                  ? machine.meta_data.wanted == null
                    ? '-'
                    : machine.meta_data.wanted
                    ? 'Yes'
                    : 'No'
                  : 'N/A'}
              </p>
            </div>
          </div>
          <h2 className="border-bottom-black">Matched Model</h2>
          {machine.meta_data?.model_manually_changed && (
            <Alert>This model has been matched manually previously.</Alert>
          )}
          <div className="row">
            <div className="col-md-6">
              <label htmlFor="model">Model</label>
              <p>{machine.model ? machine.model.name : 'N/A'}</p>
            </div>
            <div className="col-md-6">
              <label htmlFor="brand">Brand</label>
              <p>{machine.model ? machine.model.brand.name : 'N/A'}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label htmlFor="category">Category</label>
              <p>
                {machine.model ? machine.model.machine_category.name : 'N/A'}
              </p>
            </div>
            <div className="col-md-6">
              <label htmlFor="type">Type</label>
              <p>{machine.model ? machine.model.machine_type.name : 'N/A'}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label htmlFor="aplication_type">Application Type</label>
              <p>
                {machine.model ? machine.model.application_type.name : 'N/A'}
              </p>
            </div>
            <div className="col-md-6">
              <SelectModal machine={machine} setMachine={setMachine} />
            </div>
          </div>
          <h2 className="border-bottom-black">Pricing</h2>
          <div className="row">
            <div className="col-md-auto">
              <label htmlFor="price">Price</label>
              <p>
                {machine.price ? machine.price : 'N/A'}{' '}
                <span>
                  {machine.price && machine.currency ? machine.currency : ''}
                </span>
              </p>
            </div>
            <div className="col">
              <label htmlFor="additional_price_info">
                Additional Price Information
              </label>
              <p>{machine.price_info ? machine.price_info : 'N/A'}</p>
            </div>
          </div>
          <h2 className="border-bottom-black">Seller</h2>
          <div className="row">
            <div className="col-12">
              <label htmlFor="plain_seller_name">Plain Seller Name</label>
              <p>{machine.plain_seller ? machine.plain_seller : 'N/A'}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label htmlFor="matched_seller_name">Matched Seller Name</label>
              <p>{machine.seller ? machine.seller.name : 'N/A'}</p>
            </div>
          </div>
          <h2 className="border-bottom-black">Location</h2>
          <div className="row">
            <div className="col-12">
              <label htmlFor="plain_location">Plain Location</label>
              <p>{machine.plain_location ? machine.plain_location : 'N/A'}</p>
            </div>
          </div>
          <h2 className="border-bottom-black">Other Information</h2>
          <div className="row">
            <div className="col-md-6">
              <label htmlFor="source">Source</label>
              <p>{machine.source ? machine.source.name : 'N/A'}</p>
            </div>
            <div className="col-md-6">
              <label htmlFor="source_format">Source Format</label>
              <p>
                {machine.source_format ? machine.source_format.name : 'N/A'}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label htmlFor="url">URL</label>
              <p>
                {machine.url ? (
                  <a href={machine.url} target="_blank" rel="noreferrer">
                    {machine.url}
                  </a>
                ) : (
                  <p>N/A</p>
                )}
              </p>
            </div>
            <div className="col-md-6">
              <label htmlFor="site_id">Site ID</label>
              <p>{machine.site_id ? machine.site_id : 'N/A'}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <label htmlFor="additional_description">
                Additional Description
              </label>
              <p>{machine.additional_info ? machine.additional_info : 'N/A'}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label htmlFor="last_modified">Last Modified</label>
              <p>{machine.last_updated ? machine.last_updated : 'N/A'}</p>
            </div>
            <div className="col-md-6">
              <label htmlFor="date_created">Date Created</label>
              <p>{machine.created ? machine.created : 'N/A'}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <label htmlFor="created_by">Creted by</label>
              <p>
                {machine.creator ? (
                  <a href={'mailto:' + machine.creator.email}>
                    {machine.creator.email}
                  </a>
                ) : (
                  'N/A'
                )}
              </p>
            </div>
          </div>
          <div className="buttons-container">
            <Link
              to={{
                pathname: '/machine/' + id + '/edit/',
              }}
              state={{ backURL: location.state?.backURL }}
            >
              <Button color="info">Modify</Button>
            </Link>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default MachineDetail;
