import React, { useEffect, useRef, useState } from 'react';
import { FaUserAlt } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import '../assets/css/index.css';
import AuthService from '../services/AuthService';
import NavLink from './Navlink';

const SiteNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const user = AuthService.getCurrentUser();

  const navigate = useNavigate();

  const logout = async () => {
    await AuthService.logout();
    navigate('/login');
  };

  const collapseRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        collapseRef.current &&
        isOpen &&
        !collapseRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isOpen]);

  return (
    <>
      <div className="bg-corporative-blue text-white">
        <nav className="flex items-center justify-between h-14 pl-5 pr-5">
          <div className="flex items-center h-full">
            <Link
              to="/machines/page/1"
              className="no-underline text-white flex items-center font-bold text-3xl"
            >
              <img
                src={'/images/logo/gindumac-logo.639c0d40d297.png'}
                className="w-8 h-8 mr-2"
                alt="gindumac logo"
              />
              <span className="mr-4">Machines</span>
            </Link>
            <NavLink to="/labeling-app/">Labeling</NavLink>
            <NavLink to="/brands-models/">Brands & Models</NavLink>
            <NavLink to="/data-quality/">Data Quality</NavLink>
          </div>
          <div className="flex items-center" ref={collapseRef}>
            <div className="cursor-pointer text-2xl" onClick={toggle}>
              <FaUserAlt className="hover:text-slate-200" />
            </div>
            <Collapse isOpen={isOpen}>
              <div className="absolute rounded top-12 right-10 bg-white shadow-md text-black origin-top z-10 min-w-[10em]">
                <div className="text-gray-500 border-solid border-neutral-200 border-b-[1px] border-t-0 border-r-0 border-l-0 p-2">
                  {user}
                </div>
                <div>
                  <div
                    className="p-2 cursor-pointer hover:bg-gray-100"
                    onClick={logout}
                  >
                    Logout
                  </div>
                </div>
              </div>
            </Collapse>
          </div>
        </nav>
      </div>
    </>
  );
};

export default SiteNavbar;
