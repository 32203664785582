import React, { Fragment } from 'react';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { Link, useNavigate } from 'react-router-dom';
import '../assets/css/index.css';
import '../assets/css/machine-info.css';
import MachineForm from '../components/MachineForm';
import AuthService from '../services/AuthService';
import MachineService from '../services/MachineService';
import { PartialMachine, Source, SourceFormat } from '../types';

function SingleMachineView() {
  const navigate = useNavigate();
  const user = AuthService.getCurrentUser();

  const addMachine = (
    e: React.FormEvent,
    sources: Source[],
    sourceFormats: SourceFormat[]
  ) => {
    e.preventDefault();

    const plain_brand = (e.target as HTMLFormElement).elements.namedItem(
      'plain_brand'
    ) as HTMLInputElement;
    const plain_model = (e.target as HTMLFormElement).elements.namedItem(
      'plain_model'
    ) as HTMLInputElement;
    const year_manufactured = (e.target as HTMLFormElement).elements.namedItem(
      'year_manufactured'
    ) as HTMLInputElement;
    const condition = (e.target as HTMLFormElement).elements.namedItem(
      'condition'
    ) as HTMLInputElement;
    const available = (e.target as HTMLFormElement).elements.namedItem(
      'available'
    ) as HTMLInputElement;
    const price = (e.target as HTMLFormElement).elements.namedItem(
      'price'
    ) as HTMLInputElement;
    const additional_price_info = (
      e.target as HTMLFormElement
    ).elements.namedItem('additional_price_info') as HTMLInputElement;
    const currency = (e.target as HTMLFormElement).elements.namedItem(
      'currency'
    ) as HTMLInputElement;
    const plain_seller_name = (e.target as HTMLFormElement).elements.namedItem(
      'plain_seller_name'
    ) as HTMLInputElement;
    const plain_location = (e.target as HTMLFormElement).elements.namedItem(
      'plain_location'
    ) as HTMLInputElement;

    const sourceInputElement = (e.target as HTMLFormElement).elements.namedItem(
      'sources'
    ) as HTMLInputElement;

    const source = sources.find(
      (source: Source) => source.name === sourceInputElement?.value
    );

    const sourceFormatInputElement = (
      e.target as HTMLFormElement
    ).elements.namedItem('source_format') as HTMLInputElement;

    const source_format = sourceFormats.find(
      (source: Source) => source.name === sourceFormatInputElement?.value
    );

    const url = (e.target as HTMLFormElement).elements.namedItem(
      'url'
    ) as HTMLInputElement;
    const site_id = (e.target as HTMLFormElement).elements.namedItem(
      'site_id'
    ) as HTMLInputElement;
    const additional_description = (
      e.target as HTMLFormElement
    ).elements.namedItem('additional_description') as HTMLInputElement;

    if (user && source && source_format) {
      const machine: PartialMachine = {
        creator: {
          username: user,
        },
        plain_brand: plain_brand.value,
        plain_model: plain_model.value,
        currency: currency.value,
        price_info: additional_price_info.value,
        manufacturing_year: parseInt(year_manufactured.value),
        additional_info: additional_description.value,
        url: url.value,
        site_id: site_id.value,
        plain_seller: plain_seller_name.value,
        plain_location: plain_location.value,
        available: available.value === 'true' ? true : false,
        source: source,
        source_format: source_format,
        condition: condition.value,
        auto_crawled: false,
      };

      if (price.value.length > 0) machine.price = price.value;

      MachineService.createMachine(machine);

      navigate('/machines/page/1');
    } else {
      throw new Error('Please fill in all the required fields');
    }
  };

  return (
    <Fragment>
      <div className="container machine_info-container">
        <div className="edit-top_buttons">
          <Link to={'/machines/page/1'}>
            <IoMdArrowRoundBack className="arrow-back" />
          </Link>
        </div>
        <MachineForm addMachine={addMachine} isEditForm={false} />
      </div>
    </Fragment>
  );
}

export default SingleMachineView;
