import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from '../services/AuthService';
import './../assets/css/login.css';

function Login() {
  const navigate = useNavigate();

  const [authenticationError, setAuthenticationError] = useState(false);

  const login = async (e: React.FormEvent) => {
    e.preventDefault();

    const url = new URL(window.location.href);
    const args = new URLSearchParams(url.search);
    const from = args.get('from');
    const search = args.get('search');
    const path = from && search ? from + search : false;

    const username = (e.target as HTMLFormElement).elements.namedItem(
      'username'
    ) as HTMLInputElement;
    const password = (e.target as HTMLFormElement).elements.namedItem(
      'password'
    ) as HTMLInputElement;
    authenticationError;

    const loginResponse = await AuthService.login(
      username.value,
      password.value
    );

    if (loginResponse !== false) {
      if (!from) {
        navigate('/machines/page/1');
      } else {
        if (!!path) {
          navigate(path);
        } else {
          navigate(from);
        }
      }
    } else {
      setAuthenticationError(true);
    }
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    if (user) navigate('/machines/page/1');
  }, [navigate]);

  return (
    <Fragment>
      <div className="login-form">
        <form onSubmit={login}>
          <div className="logo-container">
            <img
              src={'/images/logo/gindumac-login-logo.2ae96a230949.png'}
              className="login-logo"
              alt="gindumac logo"
            />
          </div>
          <h2 className="border-bottom-black text-center">MIS</h2>
          <div className="form-group mb-3">
            <input
              id="username"
              type="text"
              className="form-control"
              placeholder="Your GINDUMAC username"
              required
            />
          </div>
          <div className="form-group mb-3">
            <input
              id="password"
              type="password"
              className="form-control"
              placeholder="Enter Password"
              required
            />
          </div>
          <label className="pull-left checkbox-inline" hidden>
            <input type="checkbox" /> Remember me
          </label>
          <button
            type="submit"
            className="btn btn-primary btn-block login-btn w-full mb-3"
          >
            Log in
          </button>
          <a
            href="#"
            className="btn btn-secondary btn-block login-btn w-full mb-3"
          >
            Register
          </a>
          <div className="clearfix">
            <a href="#" className="pull-right">
              Forgot Password?
            </a>
          </div>
        </form>
        {authenticationError && (
          <div className="auth-error">Invalid username or password</div>
        )}
      </div>
    </Fragment>
  );
}

export default Login;
