import React, { Fragment, useEffect, useState } from 'react';
import { IoIosCheckmarkCircle, IoIosCloseCircle } from 'react-icons/io';
import {
  IoArrowRedoCircle,
  IoArrowUndoCircle,
  IoRefreshCircle,
} from 'react-icons/io5';
import { Alert } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import '../assets/css/index.css';
import '../assets/css/labeling.css';
import '../assets/css/machine-info.css';
import SelectModal from '../components/SelectModal';
import MachineService from '../services/MachineService';
import { Machine } from '../types';

function LabelingApp() {
  const [machinesBash, setMachinesBash] = useState<Machine[]>([]);
  const [arrayPosition, setArrayPosition] = useState(0);
  const [prevArrayPosition, setPrevArrayPosition] = useState(0);
  const [transitionClass, setTransitionClass] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);

    MachineService.getLabelingMachines().then((data) => {
      setMachinesBash(data.results);
    });
  }, []);

  useEffect(() => {
    arrayPosition < prevArrayPosition
      ? setTransitionClass('machine_info-transition-prev')
      : setTransitionClass('machine_info-transition-next');

    setPrevArrayPosition(arrayPosition);

    document.body.classList.add('hide-scrollbar');

    const timeoutId = setTimeout(() => {
      setTransitionClass('');
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [arrayPosition]);

  const refreshMachines = () => {
    setArrayPosition(0);
    let mounted = true;
    MachineService.getLabelingMachines().then((data) => {
      if (mounted) {
        setMachinesBash(data.results);
      }
    });
    return () => {
      mounted = false;
    };
  };

  const labeling = async (wanted: boolean) => {
    let meta_data_id = '';
    if (machinesBash[arrayPosition]?.meta_data) {
      machinesBash[arrayPosition].meta_data.wanted = wanted;
      meta_data_id = machinesBash[arrayPosition]?.meta_data?.id;
    } else {
      meta_data_id = uuidv4();
      machinesBash[arrayPosition].meta_data = {
        id: meta_data_id,
        wanted: wanted,
      };
    }
    await MachineService.updateMachine(machinesBash[arrayPosition]?.id, {
      meta_data: {
        id: meta_data_id,
        wanted: wanted,
      },
    });
    if (arrayPosition !== machinesBash.length - 1) {
      setArrayPosition(arrayPosition + 1);
    }
  };
  const isLoading = machinesBash === undefined;

  return isLoading ? (
    <Fragment>
      <div className="loader"></div>
    </Fragment>
  ) : (
    <Fragment>
      <div className="container machine-counter">
        <h3>
          {arrayPosition + 1}/{machinesBash.length}
        </h3>
        <IoRefreshCircle
          className="labeling-icon refresh"
          onClick={refreshMachines}
        />
      </div>
      <div className={`container machine_info-container ${transitionClass}`}>
        <h2 className="border-bottom-black">Basic Machine Information</h2>
        <div className="row">
          <div className="col-md-12">
            <label htmlFor="mis_url">MIS URL</label>
            <p>
              <a
                href={`/machine/${machinesBash[arrayPosition]?.id}/view`}
                target="_blank"
                rel="noreferrer"
              >
                {window.location.origin}/machine/
                {machinesBash[arrayPosition]?.id}
                /view
              </a>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label htmlFor="plain_brand">Plain Brand</label>
            <p>
              {machinesBash[arrayPosition]?.plain_brand
                ? machinesBash[arrayPosition]?.plain_brand
                : 'N/A'}
            </p>
          </div>
          <div className="col-md-6">
            <label htmlFor="plain_model">Plain Model</label>
            <p>
              {machinesBash[arrayPosition]?.plain_model
                ? machinesBash[arrayPosition]?.plain_model
                : 'N/A'}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label htmlFor="year_manufactured">Year Manufactured</label>
            <p>
              {machinesBash[arrayPosition]?.manufacturing_year
                ? machinesBash[arrayPosition]?.manufacturing_year
                : 'N/A'}
            </p>
          </div>
          <div className="col-md-6">
            <label htmlFor="condition">Condition</label>
            <p>
              {machinesBash[arrayPosition]?.condition
                ? machinesBash[arrayPosition]?.condition
                : 'N/A'}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label htmlFor="available">Available</label>
            <p>
              {typeof machinesBash[arrayPosition]?.availability?.available !==
              'undefined'
                ? machinesBash[arrayPosition]?.availability?.available
                  ? 'Yes'
                  : 'No'
                : 'N/A'}
            </p>
          </div>
        </div>
        <h2 className="border-bottom-black">Matched Model</h2>
        {machinesBash[arrayPosition]?.meta_data?.model_manually_changed && (
          <Alert>This model has been matched manually previously.</Alert>
        )}
        <div className="row">
          <div className="col-md-6">
            <label htmlFor="model">Model</label>
            <p>
              {machinesBash[arrayPosition]?.model
                ? machinesBash[arrayPosition]?.model?.name
                : 'N/A'}
            </p>
          </div>
          <div className="col-md-6">
            <label htmlFor="brand">Brand</label>
            <p>
              {machinesBash[arrayPosition]?.model
                ? machinesBash[arrayPosition]?.model?.brand.name
                : 'N/A'}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label htmlFor="category">Category</label>
            <p>
              {machinesBash[arrayPosition]?.model
                ? machinesBash[arrayPosition]?.model?.machine_category.name
                : 'N/A'}
            </p>
          </div>
          <div className="col-md-6">
            <label htmlFor="type">Type</label>
            <p>
              {machinesBash[arrayPosition]?.model
                ? machinesBash[arrayPosition]?.model?.machine_type.name
                : 'N/A'}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label htmlFor="aplication_type">Application Type</label>
            <p>
              {machinesBash[arrayPosition]?.model
                ? machinesBash[arrayPosition]?.model?.application_type.name
                : 'N/A'}
            </p>
          </div>
          <div className="col-md-6">
            <SelectModal
              machinesBash={machinesBash}
              arrayPosition={arrayPosition}
              setMachinesBash={setMachinesBash}
            />
          </div>
        </div>
        <h2 className="border-bottom-black">Pricing</h2>
        <div className="row">
          <div className="col-md-auto">
            <label htmlFor="price">Price</label>
            <p>
              {machinesBash[arrayPosition]?.price
                ? machinesBash[arrayPosition]?.price
                : 'N/A'}{' '}
              <span>
                {machinesBash[arrayPosition]?.price &&
                machinesBash[arrayPosition]?.currency
                  ? machinesBash[arrayPosition]?.currency
                  : ''}
              </span>
            </p>
          </div>
          <div className="col">
            <label htmlFor="additional_price_info">
              Additional Price Information
            </label>
            <p>
              {machinesBash[arrayPosition]?.price_info
                ? machinesBash[arrayPosition]?.price_info
                : 'N/A'}
            </p>
          </div>
        </div>
        <h2 className="border-bottom-black">Seller</h2>
        <div className="row">
          <div className="col-12">
            <label htmlFor="plain_seller_name">Plain Seller Name</label>
            <p>
              {machinesBash[arrayPosition]?.plain_seller
                ? machinesBash[arrayPosition]?.plain_seller
                : 'N/A'}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label htmlFor="matched_seller_name">Matched Seller Name</label>
            <p>
              {machinesBash[arrayPosition]?.seller
                ? machinesBash[arrayPosition]?.seller?.name
                : 'N/A'}
            </p>
          </div>
        </div>
        <h2 className="border-bottom-black">Location</h2>
        <div className="row">
          <div className="col-12">
            <label htmlFor="plain_location">Plain Location</label>
            <p>
              {machinesBash[arrayPosition]?.plain_location
                ? machinesBash[arrayPosition]?.plain_location
                : 'N/A'}
            </p>
          </div>
        </div>
        <h2 className="border-bottom-black">Other Information</h2>
        <div className="row">
          <div className="col-md-6">
            <label htmlFor="source">Source</label>
            <p>
              {machinesBash[arrayPosition]?.source
                ? machinesBash[arrayPosition]?.source.name
                : 'N/A'}
            </p>
          </div>
          <div className="col-md-6">
            <label htmlFor="source_format">Source Format</label>
            <p>
              {machinesBash[arrayPosition]?.source_format
                ? machinesBash[arrayPosition]?.source_format.name
                : 'N/A'}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label htmlFor="url">URL</label>
            <div>
              {machinesBash[arrayPosition]?.url ? (
                <a
                  href={machinesBash[arrayPosition]?.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {machinesBash[arrayPosition]?.url}
                </a>
              ) : (
                <p>N/A</p>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <label htmlFor="site_id">Site ID</label>
            <p>
              {machinesBash[arrayPosition]?.site_id
                ? machinesBash[arrayPosition]?.site_id
                : 'N/A'}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <label htmlFor="additional_description">
              Additional Description
            </label>
            <p>
              {machinesBash[arrayPosition]?.additional_info
                ? machinesBash[arrayPosition]?.additional_info
                : 'N/A'}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label htmlFor="last_modified">Last Modified</label>
            <p>
              {machinesBash[arrayPosition]?.last_updated
                ? machinesBash[arrayPosition]?.last_updated
                : 'N/A'}
            </p>
          </div>
          <div className="col-md-6">
            <label htmlFor="date_created">Date Created</label>
            <p>
              {machinesBash[arrayPosition]?.created
                ? machinesBash[arrayPosition]?.created
                : 'N/A'}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <label htmlFor="created_by">Creted by</label>
            <p>
              {machinesBash[arrayPosition]?.creator ? (
                <a
                  href={'mailto:' + machinesBash[arrayPosition]?.creator?.email}
                >
                  {machinesBash[arrayPosition]?.creator?.email}
                </a>
              ) : (
                'N/A'
              )}
            </p>
          </div>
        </div>
      </div>
      <div className="labeling-options_box">
        {arrayPosition === 0 ? (
          <IoArrowUndoCircle className="labeling-icon arrow-undo disabled" />
        ) : (
          <IoArrowUndoCircle
            className="labeling-icon arrow-undo"
            onClick={() => setArrayPosition(arrayPosition - 1)}
          />
        )}

        <IoIosCheckmarkCircle
          className={
            'labeling-icon check-mark' +
            (machinesBash[arrayPosition]?.meta_data?.wanted ? ' wanted' : '')
          }
          onClick={() => labeling(true)}
        />
        <IoIosCloseCircle
          className={
            'labeling-icon close' +
            (machinesBash[arrayPosition]?.meta_data?.wanted === false
              ? ' not-wanted'
              : '')
          }
          onClick={() => labeling(false)}
        />
        {arrayPosition === machinesBash.length - 1 ? (
          <IoArrowRedoCircle className="labeling-icon arrow-redo disabled" />
        ) : (
          <IoArrowRedoCircle
            className="labeling-icon arrow-redo"
            onClick={() => setArrayPosition(arrayPosition + 1)}
          />
        )}
      </div>
    </Fragment>
  );
}
export default LabelingApp;
