import { createSlice } from '@reduxjs/toolkit';
import { Brand, Model } from '../types';

const initialState = {
  brandsOptions: <Brand[]>[],
  modelsOptions: <Model[]>[],
};

export const machinesSlice = createSlice({
  name: 'machines',
  initialState,
  reducers: {
    pushBrand: (state, action) => {
      state.brandsOptions.push(action.payload);
    },
    pushModel: (state, action) => {
      state.modelsOptions.push(action.payload);
    },
    setBrands: (state, action) => {
      state.brandsOptions = action.payload;
    },
    setModels: (state, action) => {
      state.modelsOptions = action.payload;
    },
  },
});

export const { pushBrand, pushModel, setBrands, setModels } =
  machinesSlice.actions;

export default machinesSlice.reducer;
