import React, { Fragment, useEffect, useState } from 'react';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
import '../assets/css/index.css';
import '../assets/css/machine-info.css';
import MachineForm from '../components/MachineForm';
import MachineService from '../services/MachineService';
import { Machine, PartialMachine, Source, SourceFormat } from '../types';

function SingleMachineView() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [machine, setMachine] = useState<Machine>();

  useEffect(() => {
    window.scrollTo(0, 0);

    id
      ? MachineService.getSingleMachine(id).then((data) => {
          setMachine(data);
        })
      : null;
  }, [id]);

  const editMachine = async (
    e: React.FormEvent,
    sources: Source[],
    sourceFormats: SourceFormat[]
  ) => {
    e.preventDefault();

    const plain_brand = (e.target as HTMLFormElement).elements.namedItem(
      'plain_brand'
    ) as HTMLInputElement;
    const plain_model = (e.target as HTMLFormElement).elements.namedItem(
      'plain_model'
    ) as HTMLInputElement;
    const year_manufactured = (e.target as HTMLFormElement).elements.namedItem(
      'year_manufactured'
    ) as HTMLInputElement;
    const condition = (e.target as HTMLFormElement).elements.namedItem(
      'condition'
    ) as HTMLInputElement;
    const available = (e.target as HTMLFormElement).elements.namedItem(
      'available'
    ) as HTMLInputElement;
    console.log(available.value);
    const price = (e.target as HTMLFormElement).elements.namedItem(
      'price'
    ) as HTMLInputElement;
    const additional_price_info = (
      e.target as HTMLFormElement
    ).elements.namedItem('additional_price_info') as HTMLInputElement;
    const currency = (e.target as HTMLFormElement).elements.namedItem(
      'currency'
    ) as HTMLInputElement;
    const plain_seller_name = (e.target as HTMLFormElement).elements.namedItem(
      'plain_seller_name'
    ) as HTMLInputElement;
    const plain_location = (e.target as HTMLFormElement).elements.namedItem(
      'plain_location'
    ) as HTMLInputElement;

    const sourceInputElement = (e.target as HTMLFormElement).elements.namedItem(
      'sources'
    ) as HTMLInputElement;

    const source = sources.find(
      (source: Source) => source.name === sourceInputElement?.value
    );

    const sourceFormatInputElement = (
      e.target as HTMLFormElement
    ).elements.namedItem('source_format') as HTMLInputElement;

    const source_format = sourceFormats.find(
      (source: Source) => source.name === sourceFormatInputElement?.value
    );

    const url = (e.target as HTMLFormElement).elements.namedItem(
      'url'
    ) as HTMLInputElement;
    const site_id = (e.target as HTMLFormElement).elements.namedItem(
      'site_id'
    ) as HTMLInputElement;
    const additional_description = (
      e.target as HTMLFormElement
    ).elements.namedItem('additional_description') as HTMLInputElement;

    let meta_data = machine?.meta_data;
    if (meta_data === null || meta_data === undefined) {
      meta_data = {
        id: uuidv4(),
      };
    }

    const wantedInput = (e.target as HTMLFormElement).elements.namedItem(
      'wanted'
    ) as HTMLInputElement;

    meta_data.wanted = wantedInput.checked;

    if (source && source_format) {
      const new_available = available.value === 'true' ? true : false;

      const updated_machine: PartialMachine = {
        plain_brand: plain_brand.value,
        plain_model: plain_model.value,
        currency: currency.value,
        price_info: additional_price_info.value,
        manufacturing_year: parseInt(year_manufactured.value),
        additional_info: additional_description.value,
        url: url.value,
        site_id: site_id.value,
        plain_seller: plain_seller_name.value,
        plain_location: plain_location.value,
        source: source,
        source_format: source_format,
        condition: condition.value,
        meta_data: meta_data,
      };

      if (price.value.length > 0) updated_machine.price = price.value;

      if (id) {
        await MachineService.updateMachine(id, updated_machine);
        machine?.availability.id
          ? await MachineService.updateAvailability(
              machine?.availability.id,
              new_available
            )
          : null;
      }

      navigate('/machine/' + id + '/view/', {
        state: {
          backURL:
            location.state &&
            navigate('/machine/' + id + '/view/', {
              state: {
                backURL:
                  location.state &&
                  (location.state as { backURL?: string })?.backURL,
              },
            }),
        },
      });
    } else {
      throw new Error('Source or Source Format not found when editing machine');
    }
  };

  const deleteMachine = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this machine!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        id ? MachineService.deleteMachine(id) : null;
        Swal.fire({
          title: 'Deleted!',
          text: 'The machine has been deleted.',
          icon: 'success',
        });
        navigate('/machines/page/1');
      }
    });
  };

  return (
    <Fragment>
      <div className="container machine_info-container">
        <div className="edit-top_buttons">
          <Link
            to={{
              pathname: '/machine/' + id + '/view/',
            }}
            state={{
              backURL: location.state.backURL,
            }}
          >
            <IoMdArrowRoundBack className="arrow-back" />
          </Link>
          <Button color="danger" onClick={deleteMachine}>
            Delete
          </Button>
        </div>
        {machine && id && editMachine ? (
          <MachineForm
            machine={machine}
            id={id}
            editMachine={editMachine}
            isEditForm={true}
          />
        ) : null}
      </div>
    </Fragment>
  );
}

export default SingleMachineView;
