import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Button, Collapse, Form, Input, Label } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import { pushBrand, pushModel, setBrands } from '../app/MachinesSlice';
import '../assets/css/brands-and-models.css';
import { checkIsEmpty, checkIsOverLength } from '../common';
import MachineService from '../services/MachineService';
import {
  APIResponse,
  ApplicationType,
  Brand,
  MachineCategory,
  MachineType,
  PartialBrand,
  PartialModel,
  RootState,
  SelectOptions,
} from '../types';

import Swal from 'sweetalert2';

function BrandsAndModels() {
  const [collapseModelStatus, setCollapseModelStatus] = useState(true);
  const [collapseBrandStatus, setCollapseBrandStatus] = useState(false);
  const brands = useSelector(
    (state: RootState) => state.machines.brandsOptions
  );
  const [categories, setCategories] = useState<APIResponse<MachineCategory>>();
  const [applicationTypes, setApplicationTypes] =
    useState<APIResponse<ApplicationType>>();
  const [machineTypes, setMachineTypes] = useState<APIResponse<MachineType>>();
  const dispatch = useDispatch();

  useEffect(() => {
    let mounted = true;
    MachineService.getAllBrands().then((data) => {
      dispatch(setBrands(data.results));
    });

    MachineService.getAllCategories().then((data) => {
      if (mounted) {
        setCategories(data);
      }
    });
    MachineService.getAllApplicationTypes().then((data) => {
      if (mounted) {
        setApplicationTypes(data);
      }
    });
    MachineService.getAllMachineTypes().then((data) => {
      if (mounted) {
        setMachineTypes(data);
      }
    });
    return () => {
      mounted = false;
    };
  }, []);

  const BrandsOptions: SelectOptions[] = [];
  const CategoriesOptions: SelectOptions[] = [];
  const ApplicationTypesOptions: SelectOptions[] = [];
  const MachineTypesOptions: SelectOptions[] = [];
  const [noValidFields, setNoValidFields] = useState<string[]>([]);

  const addBrand = async (e: React.FormEvent) => {
    e.preventDefault();

    const brand_name = (e.target as HTMLFormElement).elements.namedItem(
      'brand'
    ) as HTMLInputElement;

    const brand: PartialBrand = {
      id: uuidv4(),
      name: brand_name.value,
    };

    dispatch(pushBrand(brand));

    try {
      const response = await MachineService.addBrand(brand);

      if (response.status === 201) {
        Swal.fire({
          icon: 'success',
          title: 'Brand added',
          text: 'Brand added successfully',
        });
      }
    } catch (error: unknown) {
      Swal.fire({
        icon: 'error',
        title: 'Error adding brand',
        text: 'Check if this brand exists',
      });
    }

    setCollapseBrandStatus(false);
  };

  const addModel = async (e: React.FormEvent) => {
    e.preventDefault();

    const brandInputElement = (e.target as HTMLFormElement).elements.namedItem(
      'brand'
    ) as HTMLInputElement;
    const brand_obj = brands.find(
      (brand: Brand) => brand.id === brandInputElement?.value
    );

    const model_name = (e.target as HTMLFormElement).elements.namedItem(
      'model'
    ) as HTMLInputElement;

    const categoryInputElement = (
      e.target as HTMLFormElement
    ).elements.namedItem('category') as HTMLInputElement;
    const category = categories?.results.find(
      (category: MachineCategory) => category.id === categoryInputElement?.value
    );

    const applicationTypeInputElement = (
      e.target as HTMLFormElement
    ).elements.namedItem('application_type') as HTMLInputElement;
    const applicationType = applicationTypes?.results.find(
      (applicationType: ApplicationType) =>
        applicationType.id === applicationTypeInputElement?.value
    );

    const machineTypeInputElement = (
      e.target as HTMLFormElement
    ).elements.namedItem('machine_type') as HTMLInputElement;
    const machineType = machineTypes?.results.find(
      (machineType: MachineType) =>
        machineType.id === machineTypeInputElement?.value
    );

    const model: PartialModel = {
      id: uuidv4(),
      name: model_name.value,
      brand: brand_obj,
      machine_category: category,
      application_type: applicationType,
      machine_type: machineType,
    };

    dispatch(pushModel(model));

    try {
      const response = await MachineService.addModel(model);

      if (response.status === 201) {
        Swal.fire({
          icon: 'success',
          title: 'Model added',
          text: 'Model added successfully',
        });
      }
    } catch (error: unknown) {
      Swal.fire({
        icon: 'error',
        title: 'Error adding model',
        text: 'Check if this model exists',
      });
    }

    setCollapseModelStatus(false);
  };

  const validateBrandForm = (e: React.FormEvent) => {
    e.preventDefault();

    let wrongFieldsArray: string[] = [];

    wrongFieldsArray = wrongFieldsArray.concat(checkIsEmpty(e, 'brand'));

    wrongFieldsArray = wrongFieldsArray.concat(
      checkIsOverLength(e, 100, 'brand')
    );

    setNoValidFields([...wrongFieldsArray]);

    if (wrongFieldsArray.length > 0) {
      return false;
    } else {
      addBrand(e);
    }
  };

  const validateModelForm = (e: React.FormEvent) => {
    e.preventDefault();

    let wrongFieldsArray: string[] = [];

    wrongFieldsArray = wrongFieldsArray.concat(
      checkIsEmpty(
        e,
        'brand',
        'model',
        'category',
        'application_type',
        'machine_type'
      )
    );

    wrongFieldsArray = wrongFieldsArray.concat(
      checkIsOverLength(e, 100, 'model')
    );

    setNoValidFields([...wrongFieldsArray]);

    if (wrongFieldsArray.length > 0) {
      return false;
    } else {
      addModel(e);
    }
  };
  if (
    brands === undefined ||
    categories === undefined ||
    applicationTypes === undefined ||
    machineTypes === undefined
  ) {
    return (
      <Fragment>
        <div className="loader"></div>
      </Fragment>
    );
  } else {
    brands.map((brand) =>
      BrandsOptions.push({ label: brand.name, value: brand.id })
    );
    categories.results.map((category) =>
      CategoriesOptions.push({ label: category.name, value: category.id })
    );
    applicationTypes.results.map((applicationType) =>
      ApplicationTypesOptions.push({
        label: applicationType.name,
        value: applicationType.id,
      })
    );
    machineTypes.results.map((machineType) =>
      MachineTypesOptions.push({
        label: machineType.name,
        value: machineType.id,
      })
    );

    return (
      <Fragment>
        <div className="row-container ">
          <Button
            className="add-brand_btn"
            disabled={collapseBrandStatus}
            onClick={() => {
              setCollapseModelStatus(false);
              setTimeout(
                () => setCollapseBrandStatus(!collapseBrandStatus),
                500
              );
              setNoValidFields([]);
            }}
          >
            Add Brand
          </Button>
          <Button
            className="add-model_btn"
            disabled={collapseModelStatus}
            onClick={() => {
              setCollapseBrandStatus(false);
              setTimeout(
                () => setCollapseModelStatus(!collapseModelStatus),
                500
              );
              setNoValidFields([]);
            }}
          >
            Add Model
          </Button>
        </div>
        <div className="row form-container">
          <Collapse isOpen={collapseBrandStatus} className="collapse-container">
            <h2 className="border-bottom-black">Add Brand</h2>
            <Form onSubmit={validateBrandForm}>
              <Label htmlFor="brand" className="required-label">
                Brand
              </Label>
              <Input
                type="text"
                name="brand"
                placeholder="Name"
                invalid={noValidFields.includes('brand')}
              />
              <Button type="submit">Add Brand</Button>
            </Form>
          </Collapse>
          <Collapse isOpen={collapseModelStatus} className="collapse-container">
            <h2 className="border-bottom-black">Add Model</h2>
            <Form onSubmit={validateModelForm}>
              <div className="row">
                <div className="col-6">
                  <Label htmlFor="brand" className="required-label">
                    Brand
                  </Label>
                  <Select
                    className={
                      'multiselect-brand_model ' +
                      (noValidFields.includes('brand') ? 'is-invalid' : '')
                    }
                    options={BrandsOptions}
                    name="brand"
                    placeholder="Pick one"
                  />
                </div>
                <div className="col-6">
                  <Label htmlFor="model" className="required-label">
                    Model
                  </Label>
                  <Input
                    type="text"
                    id="model"
                    placeholder="Name"
                    invalid={noValidFields.includes('model')}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <Label htmlFor="category" className="required-label">
                    Category
                  </Label>
                  <Select
                    className={
                      'multiselect-brand_model ' +
                      (noValidFields.includes('category') ? 'is-invalid' : '')
                    }
                    options={CategoriesOptions}
                    name="category"
                    placeholder="Pick one"
                  />
                </div>
                <div className="col-6">
                  <Label htmlFor="application_type" className="required-label">
                    Application Type
                  </Label>
                  <Select
                    className={
                      'multiselect-brand_model ' +
                      (noValidFields.includes('application_type')
                        ? 'is-invalid'
                        : '')
                    }
                    options={ApplicationTypesOptions}
                    name="application_type"
                    placeholder="Pick one"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <Label htmlFor="machine_type" className="required-label">
                    Machine Type
                  </Label>
                  <Select
                    className={
                      'multiselect-brand_model ' +
                      (noValidFields.includes('machine_type')
                        ? 'is-invalid'
                        : '')
                    }
                    options={MachineTypesOptions}
                    name="machine_type"
                    placeholder="Pick one"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <Button type="submit">Add Model</Button>
                </div>
              </div>
            </Form>
          </Collapse>
        </div>
      </Fragment>
    );
  }
}

export default BrandsAndModels;
