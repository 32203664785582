import React from 'react';
import { useNavigate } from 'react-router-dom';

interface NavLinkParams {
  to: string;
  children: React.ReactNode;
}

function NavLink({ to, children }: NavLinkParams) {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate(to);
  };

  return (
    <div
      onClick={handleNavigation}
      className="flex items-center h-full text-white no-underline pr-4 pl-4 cursor-pointer hover:bg-white/50"
    >
      {children}
    </div>
  );
}

export default NavLink;
