import React, { Fragment, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Select from 'react-select';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import '../assets/css/index.css';
import '../assets/css/machine-info.css';
import MachineService from '../services/MachineService';
import {
  APIResponse,
  Machine,
  SelectOptions,
  Source,
  SourceFormat,
} from '../types';

interface MachineFormParams {
  isEditForm?: boolean;
  machine?: Machine;
  id?: string;
  addMachine?: (
    _e: React.FormEvent,
    _sources: Source[],
    _sourceFormats: SourceFormat[]
  ) => void;
  editMachine?: (
    _e: React.FormEvent,
    _sources: Source[],
    _sourceFormats: SourceFormat[]
  ) => Promise<void>;
}

function MachineForm({
  isEditForm,
  machine,
  id,
  addMachine,
  editMachine,
}: MachineFormParams) {
  const [noValidFields, setNoValidFields] = useState<string[]>([]);
  const [sources, setSources] = useState<APIResponse<Source>>();
  const [sourceFormats, setSourceFormats] =
    useState<APIResponse<SourceFormat>>();
  const location = useLocation();

  useEffect(() => {
    let mounted = true;
    MachineService.getAllSources().then((data) => {
      if (mounted) {
        setSources(data);
      }
    });
    MachineService.getAllSourceFormats().then((data) => {
      if (mounted) {
        setSourceFormats(data);
      }
    });
    return () => {
      mounted = false;
    };
  }, []);

  const checkIsEmpty = (
    e: React.FormEvent,
    fieldsArr: string[],
    ...fieldsToCheck: string[]
  ) => {
    fieldsToCheck.forEach((field) => {
      const input = (e.target as HTMLFormElement).elements.namedItem(
        field
      ) as HTMLInputElement;

      if (input.value.length === 0) {
        fieldsArr.push(field);
      }
    });
  };

  const checkIsOverLength = (
    e: React.FormEvent,
    fieldsArr: string[],
    maxLength: number,
    ...fieldsToCheck: string[]
  ) => {
    fieldsToCheck.forEach((field) => {
      const input = (e.target as HTMLFormElement).elements.namedItem(
        field
      ) as HTMLInputElement;

      if (input.value.length > maxLength) {
        fieldsArr.push(field);
      }
    });
  };

  const validateForm = (e: React.FormEvent) => {
    e.preventDefault();

    const fieldsArr: string[] = [];

    checkIsEmpty(
      e,
      fieldsArr,
      'plain_model',
      'condition',
      'available',
      'plain_seller_name',
      'sources',
      'source_format'
    );

    checkIsOverLength(
      e,
      fieldsArr,
      200,
      'plain_brand',
      'plain_model',
      'url',
      'site_id'
    );

    checkIsOverLength(e, fieldsArr, 100, 'additional_price_info');
    checkIsOverLength(e, fieldsArr, 50, 'plain_seller_name');
    checkIsOverLength(e, fieldsArr, 300, 'plain_location');

    const year_manufactured = (e.target as HTMLFormElement).elements.namedItem(
      'year_manufactured'
    ) as HTMLInputElement;

    if (
      year_manufactured.value.length !== 0 &&
      (parseInt(year_manufactured.value) > new Date().getFullYear() ||
        parseInt(year_manufactured.value) < 1900)
    ) {
      fieldsArr.push('year_manufactured');
    }

    setNoValidFields([...fieldsArr]);

    if (fieldsArr.length > 0) {
      window.scrollTo(0, 0);
      return false;
    } else {
      if (isEditForm) {
        if (editMachine && sources && sourceFormats)
          editMachine(e, sources.results, sourceFormats.results);
      } else {
        if (addMachine && sources && sourceFormats)
          addMachine(e, sources.results, sourceFormats.results);
      }
    }
  };

  const ConditionOptions = [
    { label: 'used', value: 'used' },
    { label: 'new', value: 'new' },
  ];
  const AvailableOptions = [
    { label: 'yes', value: 'true' },
    { label: 'no', value: 'false' },
  ];
  const WantedOptions = [
    { label: 'yes', value: 'true' },
    { label: 'no', value: 'false' },
    { label: '-', value: 'null' },
  ];
  const CurrenciesOptions = [
    { label: 'EUR', value: 'EUR' },
    { label: 'USD', value: 'USD' },
    { label: 'GBP', value: 'GBP' },
    { label: 'INR', value: 'INR' },
  ];
  const SourcesOptions: SelectOptions[] = [];
  const SourceFormatsOptions: SelectOptions[] = [];

  if (
    sources === undefined ||
    sourceFormats === undefined ||
    (isEditForm && machine === undefined)
  ) {
    return (
      <Fragment>
        <div className="loader"></div>
      </Fragment>
    );
  } else {
    sources.results.map((source) =>
      SourcesOptions.push({ label: source.name, value: source.name })
    );
    sourceFormats.results.map((sourceFormats) =>
      SourceFormatsOptions.push({
        label: sourceFormats.name,
        value: sourceFormats.name,
      })
    );
    return (
      <Form onSubmit={validateForm}>
        {noValidFields.length > 0 && (
          <span className="form_error-msg">Please check invalid fields</span>
        )}
        <h2 className="border-bottom-black">Basic Machine Information</h2>
        <div className="row">
          <div className="col-md-6">
            {noValidFields.includes('plain_brand') ? (
              <FormGroup>
                <Label htmlFor="plain_brand">Plain Brand</Label>
                <Input
                  invalid
                  type="text"
                  id="plain_brand"
                  defaultValue={
                    isEditForm
                      ? machine?.plain_brand
                        ? machine?.plain_brand
                        : ''
                      : ''
                  }
                />
                <FormFeedback>Max 200 characters</FormFeedback>
              </FormGroup>
            ) : (
              <FormGroup>
                <Label htmlFor="plain_brand">Plain Brand</Label>
                <Input
                  type="text"
                  id="plain_brand"
                  defaultValue={
                    isEditForm
                      ? machine?.plain_brand
                        ? machine?.plain_brand
                        : ''
                      : ''
                  }
                />
              </FormGroup>
            )}
          </div>
          <div className="col-md-6">
            {noValidFields.includes('plain_model') ? (
              <FormGroup>
                <Label htmlFor="plain_model" className="required-label">
                  Plain Model
                </Label>
                <Input
                  invalid
                  type="text"
                  id="plain_model"
                  defaultValue={
                    isEditForm
                      ? machine?.plain_model
                        ? machine?.plain_model
                        : ''
                      : ''
                  }
                />
                <FormFeedback>This field is required</FormFeedback>
                <FormFeedback>Max 200 characters</FormFeedback>
              </FormGroup>
            ) : (
              <FormGroup>
                <Label htmlFor="plain_model" className="required-label">
                  Plain Model
                </Label>
                <Input
                  type="text"
                  id="plain_model"
                  defaultValue={
                    isEditForm
                      ? machine?.plain_model
                        ? machine?.plain_model
                        : ''
                      : ''
                  }
                />
              </FormGroup>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            {noValidFields.includes('year_manufactured') ? (
              <FormGroup>
                <Label htmlFor="year_manufactured">Year Manufactured</Label>
                <Input
                  invalid
                  type="number"
                  min="1"
                  step="1"
                  id="year_manufactured"
                  defaultValue={
                    isEditForm
                      ? machine?.manufacturing_year
                        ? machine?.manufacturing_year
                        : ''
                      : ''
                  }
                />
                <FormFeedback>
                  Set a year between 1900 and {new Date().getFullYear()}
                </FormFeedback>
              </FormGroup>
            ) : (
              <FormGroup>
                <Label htmlFor="year_manufactured">Year Manufactured</Label>
                <Input
                  type="number"
                  id="year_manufactured"
                  defaultValue={
                    isEditForm
                      ? machine?.manufacturing_year
                        ? machine?.manufacturing_year
                        : ''
                      : ''
                  }
                />
              </FormGroup>
            )}
          </div>
          {noValidFields.includes('condition') ? (
            <div className="col-md-6">
              <Label htmlFor="condition" className="required-label">
                Condition
              </Label>
              <Select
                className="multiselect-create_add is-invalid"
                options={ConditionOptions}
                name="condition"
                defaultValue={
                  isEditForm
                    ? machine?.condition
                      ? ConditionOptions.find(
                          (condition) => condition.value === machine.condition
                        )
                      : ''
                    : ''
                }
              />
              <FormFeedback className="invalid-feedback">
                Choose one
              </FormFeedback>
            </div>
          ) : (
            <div className="col-md-6">
              <label htmlFor="condition" className="required-label">
                Condition
              </label>
              <Select
                className="multiselect-create_add"
                options={ConditionOptions}
                name="condition"
                defaultValue={
                  isEditForm
                    ? machine?.condition
                      ? ConditionOptions.find(
                          (condition) => condition.value === machine.condition
                        )
                      : ''
                    : ''
                }
              />
            </div>
          )}
        </div>
        <div className="row">
          {noValidFields.includes('available') ? (
            <div className="col-md-6">
              <Label htmlFor="available" className="required-label">
                Available
              </Label>
              <Select
                className="multiselect-create_add is-invalid"
                options={AvailableOptions}
                name="available"
                defaultValue={
                  isEditForm
                    ? machine?.availability?.available
                      ? AvailableOptions.find(
                          (option) => option.value === 'true'
                        )
                      : AvailableOptions.find(
                          (option) => option.value === 'false'
                        )
                    : ''
                }
              />
              <FormFeedback className="invalid-feedback">
                Choose one
              </FormFeedback>
            </div>
          ) : (
            <div className="col-md-6">
              <Label htmlFor="available" className="required-label">
                Available
              </Label>
              <Select
                className="multiselect-create_add"
                options={AvailableOptions}
                name="available"
                defaultValue={
                  isEditForm
                    ? machine?.availability?.available
                      ? AvailableOptions.find(
                          (option) => option.value === 'true'
                        )
                      : AvailableOptions.find(
                          (option) => option.value === 'false'
                        )
                    : ''
                }
              />
            </div>
          )}
          <div className="col-md-6">
            <Label htmlFor="useful">Useful</Label>
            <Select
              className="multiselect-create_add"
              options={WantedOptions}
              name="wanted"
              defaultValue={
                isEditForm
                  ? machine?.meta_data
                    ? WantedOptions.find(
                        (option) =>
                          option.value === String(machine?.meta_data.wanted)
                      )
                    : ''
                  : 'true'
              }
            />
          </div>
        </div>
        {isEditForm && (
          <Fragment>
            <h2 className="border-bottom-black">Matched Model</h2>
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="model">Model</label>
                <p>{machine?.model ? machine?.model.name : 'N/A'}</p>
              </div>
              <div className="col-md-6">
                <label htmlFor="brand">Brand</label>
                <p>{machine?.model ? machine?.model.brand.name : 'N/A'}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="category">Category</label>
                <p>
                  {machine?.model
                    ? machine?.model.machine_category.name
                    : 'N/A'}
                </p>
              </div>
              <div className="col-md-6">
                <label htmlFor="type">Type</label>
                <p>
                  {machine?.model ? machine?.model.machine_type.name : 'N/A'}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="aplication_type">Application Type</label>
                <p>
                  {machine?.model
                    ? machine?.model.application_type.name
                    : 'N/A'}
                </p>
              </div>
            </div>
          </Fragment>
        )}
        <h2 className="border-bottom-black">Pricing</h2>
        <div className="row">
          <div className="col-md-6">
            <Label htmlFor="price">Price</Label>
            <div className="price-form">
              <Input
                type="number"
                min="1"
                step="1"
                id="price"
                className="price-input"
                defaultValue={
                  isEditForm ? (machine?.price ? machine?.price : '') : ''
                }
              />
              <Select
                className="multiselect-create_add"
                options={CurrenciesOptions}
                name="currency"
                defaultValue={
                  isEditForm
                    ? machine?.currency
                      ? CurrenciesOptions.find(
                          (option) => option.value === machine?.currency
                        )
                      : ''
                    : ''
                }
              />
            </div>
          </div>
          {noValidFields.includes('additional_price_info') ? (
            <div className="col-md-6">
              <FormGroup>
                <Label htmlFor="additional_price_info">
                  Additional Price Information
                </Label>
                <Input
                  invalid
                  type="text"
                  id="additional_price_info"
                  defaultValue={
                    isEditForm
                      ? machine?.price_info
                        ? machine?.price_info
                        : ''
                      : ''
                  }
                />
                <FormFeedback className="invalid-feedback">
                  Max 100 characters
                </FormFeedback>
              </FormGroup>
            </div>
          ) : (
            <div className="col-md-6">
              <FormGroup>
                <Label htmlFor="additional_price_info">
                  Additional Price Information
                </Label>
                <Input
                  type="text"
                  id="additional_price_info"
                  defaultValue={
                    isEditForm
                      ? machine?.price_info
                        ? machine?.price_info
                        : ''
                      : ''
                  }
                />
              </FormGroup>
            </div>
          )}
        </div>
        <h2 className="border-bottom-black">Seller</h2>
        <div className="row">
          <div className="col-md-6">
            {noValidFields.includes('plain_seller_name') ? (
              <FormGroup>
                <Label htmlFor="plain_seller_name" className="required-label">
                  Plain Seller Name
                </Label>
                <Input
                  invalid
                  type="text"
                  id="plain_seller_name"
                  defaultValue={
                    isEditForm
                      ? machine?.plain_seller
                        ? machine?.plain_seller
                        : ''
                      : ''
                  }
                />
                <FormFeedback className="invalid-feedback">
                  This field is required
                </FormFeedback>
                <FormFeedback className="invalid-feedback">
                  Max 50 characters
                </FormFeedback>
              </FormGroup>
            ) : (
              <FormGroup>
                <Label htmlFor="plain_seller_name" className="required-label">
                  Plain Seller Name
                </Label>
                <Input
                  type="text"
                  id="plain_seller_name"
                  defaultValue={
                    isEditForm
                      ? machine?.plain_seller
                        ? machine?.plain_seller
                        : ''
                      : ''
                  }
                />
              </FormGroup>
            )}
          </div>
        </div>
        <h2 className="border-bottom-black">Location</h2>
        <div className="row">
          <div className="col-12">
            {noValidFields.includes('plain_location') ? (
              <FormGroup>
                <Label htmlFor="plain_location">Plain Location</Label>
                <Input
                  invalid
                  type="text"
                  id="plain_location"
                  defaultValue={
                    isEditForm
                      ? machine?.plain_location
                        ? machine?.plain_location
                        : ''
                      : ''
                  }
                />
                <FormFeedback className="invalid-feedback">
                  Max 300 characters
                </FormFeedback>
              </FormGroup>
            ) : (
              <FormGroup>
                <Label htmlFor="plain_location">Plain Location</Label>
                <Input
                  type="text"
                  id="plain_location"
                  defaultValue={
                    isEditForm
                      ? machine?.plain_location
                        ? machine?.plain_location
                        : ''
                      : ''
                  }
                />
              </FormGroup>
            )}
          </div>
        </div>
        <h2 className="border-bottom-black">Other Information</h2>
        <div className="row">
          {noValidFields.includes('sources') ? (
            <div className="col-md-6">
              <Label htmlFor="sources" className="required-label">
                Source
              </Label>
              <Select
                className="multiselect-create_add is-invalid"
                options={SourcesOptions}
                defaultValue={
                  isEditForm
                    ? machine?.source
                      ? SourcesOptions.find(
                          (option) => option.value === machine?.source.name
                        )
                      : ''
                    : ''
                }
                name="sources"
              />
              <FormFeedback className="invalid-feedback">
                Choose one
              </FormFeedback>
            </div>
          ) : (
            <div className="col-md-6">
              <Label htmlFor="sources" className="required-label">
                Source
              </Label>
              <Select
                className="multiselect-create_add"
                options={SourcesOptions}
                defaultValue={
                  isEditForm
                    ? machine?.source
                      ? SourcesOptions.find(
                          (option) => option.value === machine?.source.name
                        )
                      : ''
                    : ''
                }
                name="sources"
              />
            </div>
          )}
          {noValidFields.includes('source_format') ? (
            <div className="col-md-6">
              <Label htmlFor="source_format" className="required-label">
                Source Format
              </Label>
              <Select
                className="multiselect-create_add is-invalid"
                options={SourceFormatsOptions}
                defaultValue={
                  isEditForm
                    ? machine?.source
                      ? SourceFormatsOptions.find(
                          (option) =>
                            option.value === machine?.source_format.name
                        )
                      : ''
                    : ''
                }
                name="source_format"
              />
              <FormFeedback className="invalid-feedback">
                Choose one
              </FormFeedback>
            </div>
          ) : (
            <div className="col-md-6">
              <Label htmlFor="source_format" className="required-label">
                Source Format
              </Label>
              <Select
                className="multiselect-create_add"
                options={SourceFormatsOptions}
                defaultValue={
                  isEditForm
                    ? machine?.source
                      ? SourceFormatsOptions.find(
                          (option) =>
                            option.value === machine?.source_format.name
                        )
                      : ''
                    : ''
                }
                name="source_format"
              />
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-md-6">
            {noValidFields.includes('url') ? (
              <FormGroup>
                <Label htmlFor="url">URL</Label>
                <Input
                  invalid
                  type="text"
                  id="url"
                  defaultValue={
                    isEditForm ? (machine?.url ? machine?.url : '') : ''
                  }
                />
                <FormFeedback className="invalid-feedback">
                  Max 200 characters
                </FormFeedback>
              </FormGroup>
            ) : (
              <FormGroup>
                <Label htmlFor="url">URL</Label>
                <Input
                  type="text"
                  id="url"
                  defaultValue={
                    isEditForm ? (machine?.url ? machine?.url : '') : ''
                  }
                />
              </FormGroup>
            )}
          </div>
          <div className="col-md-6">
            {noValidFields.includes('site_id') ? (
              <FormGroup>
                <Label htmlFor="site_id">Site ID</Label>
                <Input
                  invalid
                  type="text"
                  id="site_id"
                  defaultValue={
                    isEditForm ? (machine?.site_id ? machine?.site_id : '') : ''
                  }
                />
                <FormFeedback className="invalid-feedback">
                  Max 200 characters
                </FormFeedback>
              </FormGroup>
            ) : (
              <FormGroup>
                <Label htmlFor="site_id">Site ID</Label>
                <Input
                  type="text"
                  id="site_id"
                  defaultValue={
                    isEditForm ? (machine?.site_id ? machine?.site_id : '') : ''
                  }
                />
              </FormGroup>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Label htmlFor="additional_description">
              Additional Description
            </Label>
            <Input
              type="textarea"
              id="additional_description"
              name="additional_description"
              rows="4"
              defaultValue={
                isEditForm
                  ? machine?.additional_info
                    ? machine?.additional_info
                    : ''
                  : ''
              }
            />
          </div>
        </div>
        {isEditForm && (
          <Fragment>
            <div className="row">
              <div className="col-md-6">
                <Label htmlFor="last_modified">Last Modified</Label>
                <p>{machine?.last_updated ? machine?.last_updated : ''}</p>
              </div>
              <div className="col-md-6">
                <Label htmlFor="date_created">Date Created</Label>
                <p>{machine?.created ? machine?.created : ''}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Label htmlFor="created_by">Created by</Label>
                <p>
                  <a
                    href={
                      'mailto:' + machine?.creator !== undefined
                        ? machine?.creator.email
                        : ''
                    }
                  >
                    {machine?.creator !== undefined
                      ? machine?.creator.email
                      : ''}
                  </a>
                </p>
              </div>
            </div>
          </Fragment>
        )}
        <div className="buttons-container">
          <Button type="submit" color="info">
            {isEditForm ? 'Apply' : 'Add'}
          </Button>
          {isEditForm ? (
            <Link
              to={{
                pathname: '/machine/' + id + '/view/',
              }}
              state={{
                backURL: location.state.backURL,
              }}
            >
              <Button color="secondary">Cancel</Button>
            </Link>
          ) : (
            <Link to={'/machines/page/1'}>
              <Button color="secondary">Cancel</Button>
            </Link>
          )}
        </div>
      </Form>
    );
  }
}

export default MachineForm;
