import React, { useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Button, Form, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import { setBrands, setModels } from '../app/MachinesSlice';
import { checkIsEmpty } from '../common';
import BrandsAndModels from '../pages/BrandsAndModels';
import MachineService from '../services/MachineService';
import { Machine, Model, RootState } from '../types';

function AddModelModal() {
  const [isOpenAddModelModal, setIsOpenAddModelModal] = useState(false);

  return (
    <>
      <div className="add-model_txt">
        <span onClick={() => setIsOpenAddModelModal(true)}>
          {'Or create a new brand or model'}
        </span>
      </div>
      <Modal isOpen={isOpenAddModelModal} className="add-model_modal">
        <ModalBody>
          <div className="add-model-modal_header">
            <IoClose
              onClick={() => setIsOpenAddModelModal(false)}
              className="add-model-modal_close-button"
            />
          </div>
          <BrandsAndModels />
        </ModalBody>
      </Modal>
    </>
  );
}

export interface ModalFormParams {
  machinesBash?: Machine[];
  arrayPosition?: number;
  setMachinesBash?: React.Dispatch<React.SetStateAction<Machine[]>>;
  setModalStatus?: React.Dispatch<React.SetStateAction<boolean>>;
  machine?: Machine;
  setMachine?: React.Dispatch<React.SetStateAction<Machine | undefined>>;
}

function ModalForm({
  machinesBash,
  arrayPosition,
  setModalStatus,
  setMachinesBash,
  machine,
  setMachine,
}: ModalFormParams) {
  const [selectedBrand, setSelectedBrand] = useState<string | undefined>();
  const [noValidFields, setNoValidFields] = useState<string[]>([]);
  const brands = useSelector(
    (state: RootState) => state.machines.brandsOptions
  );
  const models = useSelector(
    (state: RootState) => state.machines.modelsOptions
  );

  const dispatch = useDispatch();

  useEffect(() => {
    MachineService.getAllBrands().then((data) => {
      dispatch(setBrands(data.results));
    });

    MachineService.getAllModels().then((data) => {
      dispatch(setModels(data.results));
    });
  }, []);

  const validateForm = (e: React.FormEvent) => {
    e.preventDefault();

    let wrongFieldsArray: string[] = [];

    wrongFieldsArray = wrongFieldsArray.concat(
      checkIsEmpty(e, 'brand', 'model')
    );

    setNoValidFields([...wrongFieldsArray]);

    if (wrongFieldsArray.length > 0) {
      return false;
    } else {
      updateMachine(e);
    }
  };

  const updateMachine = (e: React.FormEvent) => {
    e.preventDefault();

    const modelInputElement = (e.target as HTMLFormElement).elements.namedItem(
      'model'
    ) as HTMLInputElement;

    const model_obj = models.find(
      (model: Model) => model.id === modelInputElement?.value
    );

    let meta_data;

    if (machine) {
      meta_data = machine.meta_data;
    } else if (machinesBash !== undefined && arrayPosition !== undefined) {
      meta_data = machinesBash[arrayPosition].meta_data;
    }

    let data = {};
    if (meta_data === null) {
      data = {
        model: model_obj,
        meta_data: {
          id: uuidv4(),
          model_manually_changed: true,
        },
      };
    } else {
      data = {
        model: model_obj,
        meta_data: {
          id: meta_data?.id,
          model_manually_changed: true,
        },
      };
    }

    if (machine && setMachine) {
      model_obj ? setMachine({ ...machine, model: model_obj }) : null;
      MachineService.updateMachine(machine.id, data);
    } else {
      const newBash = machinesBash ? [...machinesBash] : [];
      arrayPosition !== undefined && model_obj !== undefined
        ? (newBash[arrayPosition].model = model_obj)
        : null;
      setMachinesBash ? setMachinesBash([...newBash]) : null;

      machinesBash !== undefined && arrayPosition !== undefined
        ? MachineService.updateMachine(machinesBash[arrayPosition].id, data)
        : null;
    }
    setModalStatus ? setModalStatus(false) : false;
  };

  return (
    <ModalBody>
      <div className="change-model_form">
        <Form onSubmit={(e) => validateForm(e)}>
          <Select
            className={
              'mb-2 multiselect-model ' +
              (noValidFields.includes('brand') ? 'is-invalid' : '')
            }
            options={brands.map((brand) => ({
              label: brand.name,
              value: brand.id,
            }))}
            name="brand"
            placeholder={'Brand'}
            onChange={(submitted) =>
              setSelectedBrand(submitted ? submitted.value : undefined)
            }
          />
          <Select
            className={
              'multiselect-model ' +
              (noValidFields.includes('model') ? 'is-invalid' : '')
            }
            options={
              selectedBrand && models
                ? models
                    .filter((model) => model.brand.id === selectedBrand)
                    .map((model) => ({ label: model.name, value: model.id }))
                : []
            }
            name="model"
            placeholder={'Model'}
          />
          <div className="change-model_form-buttons">
            <Button type="submit" color="primary">
              Change Model
            </Button>
          </div>
        </Form>
        <AddModelModal />
      </div>
    </ModalBody>
  );
}

interface SelectModalParams {
  machinesBash?: Machine[];
  arrayPosition?: number;
  setMachinesBash?: React.Dispatch<React.SetStateAction<Machine[]>>;
  machine?: Machine;
  setMachine?: React.Dispatch<React.SetStateAction<Machine | undefined>>;
}

function SelectModal({
  machinesBash,
  arrayPosition,
  setMachinesBash,
  machine,
  setMachine,
}: SelectModalParams) {
  const [modalStatus, setModalStatus] = useState(false);

  const brands = useSelector(
    (state: RootState) => state.machines.brandsOptions
  );
  const models = useSelector(
    (state: RootState) => state.machines.modelsOptions
  );

  const isLoading = brands.length === 0 || models.length === 0;

  const dispatch = useDispatch();

  useEffect(() => {
    MachineService.getAllBrands().then((data) => {
      dispatch(setBrands(data.results));
    });

    MachineService.getAllModels().then((data) => {
      dispatch(setModels(data.results));
    });
  }, []);

  if (isLoading) {
    return (
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    );
  }
  return (
    <>
      <Button
        className="match-manually_btn"
        onClick={() => setModalStatus(true)}
      >
        Match manually
      </Button>
      <Modal backdrop={true} isOpen={modalStatus} className="select_modal">
        <ModalHeader>
          <div className="add-model-modal_header">
            Select Model
            <IoClose
              onClick={() => setModalStatus(false)}
              className="add-model-modal_close-button"
            />
          </div>
        </ModalHeader>
        <ModalForm
          machinesBash={machinesBash}
          arrayPosition={arrayPosition}
          setModalStatus={setModalStatus}
          setMachinesBash={setMachinesBash}
          machine={machine}
          setMachine={setMachine}
        />
      </Modal>
    </>
  );
}

export default SelectModal;
