import axios from 'axios';
import MachineService from './MachineService';

class AuthService {
  private logInUrl: string;
  private logOutUrl: string;

  constructor() {
    this.logInUrl = process.env.REACT_APP_API_URL + 'api-auth/login/';
    this.logOutUrl = process.env.REACT_APP_API_URL + 'api-auth/logout/';
  }

  async login(username: string, password: string) {
    let successLogin;

    await axios({
      url: this.logInUrl,
      method: 'POST',
      data: {
        username: username,
        password: password,
      },
    })
      .then((response) => {
        MachineService.setToken(response.data.token);
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user', username);
        successLogin = true;
      })
      .catch(() => {
        successLogin = false;
      });

    return successLogin;
  }

  logout() {
    axios({
      url: this.logOutUrl,
      method: 'GET',
      headers: {
        Authorization: `Token ${localStorage.getItem('token')}`,
      },
    });

    localStorage.removeItem('user');
    localStorage.removeItem('token');
  }

  getCurrentUser() {
    return localStorage.getItem('user');
  }

  getToken() {
    return localStorage.getItem('token');
  }
}

export default new AuthService();
